import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./loggedin.css";

const Login = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const onButtonClick = () => {
    // Save user data to localStorage
    const userData = {
      username,
      email,
      password,
      userType: username.toLowerCase().includes('admin') ? 'admin' : 'user'
    };
    localStorage.setItem('userData', JSON.stringify(userData));
  
    // Check if user type is admin
    if (userData.userType === 'admin') {
      // Navigate to admin landing page
      navigate('/adminlanding');
    } else {
      // Navigate to regular landing page
      navigate('/landing');
    }
  };

  return (
    <div className={'mainContainer'}>
      <div className={'titleContainer'}>
        <div>Login</div>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={username}
          placeholder="Enter your username here"
          onChange={(ev) => setUsername(ev.target.value)}
          className={'inputBox'}
        />
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={email}
          placeholder="Enter your email here"
          onChange={(ev) => setEmail(ev.target.value)}
          className={'inputBox'}
        />
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={password}
          type="password"
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          className={'inputBox'}
        />
      </div>
      <br />
      <div className={'inputContainer'}>
        <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Log in'} />
      </div>
    </div>
  );
};

export default Login;
