// import React from 'react'
// import "./emoticonBar.css";

// export default function EmoticonBar() {
//   const emoticons = ['😀', '😂', '😊', '😍', '🥰']; // Example emojis

//   const handleEmoticonClick = (emoticon) => {
//     console.log("Emoticon clicked:", emoticon);
//     // Handle emoticon click here
//   };
//   return (
//     <div className="emoticon-bar">
//       {emoticons.map((emoticon, index) => (
//         <span key={index} className="emoticon" onClick={() => handleEmoticonClick(emoticon)}>
//           {emoticon}
//         </span>
//       ))}
//     </div>
//   );
// }

// EmoticonBar.js

// import React from 'react';
// import './emoticonBar.css';

// export default function EmoticonBar() {
//   const emoticons = ['😀', '😂', '😊', '😍', '🥰']; // Example emojis

//   const handleEmoticonClick = (emoticon) => {
//     console.log("Emoticon clicked:", emoticon);
  
//   };

//   return (
//     <div className="emoticon-bar">
//       {emoticons.map((emoticon, index) => (
//         <span 
//           key={index} 
//           className="emoticon" 
//           data-emoticon={emoticon} 
//           onClick={() => handleEmoticonClick(emoticon)}
//         >
//           {emoticon}
//         </span>
//       ))}
//     </div>
//   );
// }



// import React from 'react';
// import './emoticonBar.css';

// export default function EmoticonBar(props) {
//   const emoticons = ['😀', '😂', '😊', '😍', '🥰']; // Example emojis

//   const handleEmoticonClick = (emoticon) => {
//     console.log("Emoticon clicked:", emoticon);
//     props.sendEmojiToRemote(emoticon);
//   };

//   return (
//     <div className="emoticon-bar">
//       {emoticons.map((emoticon, index) => (
//         <span 
//           key={index} 
//           className="emoticon" 
//           data-emoticon={emoticon} 
//           onClick={() => handleEmoticonClick(emoticon)}
//         >
//           {emoticon}
//         </span>
//       ))}
//     </div>
//   );
// }


import React from 'react';
import './emoticonBar.css';

export default function EmoticonBar(props) {

  const materialIcons = [ '😀', '😂', '😊', '😍', '🥰']; 

  const handleEmoticonClick = (materialIcon) => {
    console.log("Material Icon clicked:", materialIcon);
    props.sendEmojiToRemote(materialIcon); // Send Icon to parent component
  };

  return (
    <div className="emoticon-bar">
      {materialIcons.map((icon, index) => (
        <span 
          key={index} 
          className="material-icon" 
          onClick={() => handleEmoticonClick(icon)}
        >
          {/* Render the Material Icon */}
          <i className="material-icons">{icon}</i>
        </span>
      ))}
    </div>
  );
}
