// CreateViewPackagePage.js

import React from 'react';
import './PackagePage.css';
import CreatePackage from './CreatePackage';
import { useNavigate } from 'react-router-dom';

const PackagePage = () => {
  const navigate = useNavigate();

  const handlecreatepackage =()=>{
    navigate('/CreatePackage');
  }
  
  const handleviewpackage =()=>{
    navigate('/ViewPackage');
  }

  const handleaddfeatures =()=>{
    navigate('/addfeatures');
  }

  return (
    <div className="container">
        <header>
        <img
          src="/images/logo.png"
          alt="ConnectXpert Logo"
          className="logo"
        />
      </header>
      <div className="button-container">
      <button className="view-package-button" onClick={handleaddfeatures}>Add Features</button>
        <button className="create-package-button" onClick={handlecreatepackage}>Create Package</button>
        <button className="view-package-button" onClick={handleviewpackage}>View Package</button>
      </div>
    </div>
  );
};

export default PackagePage;
