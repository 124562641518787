import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ViewPackage.css';

const ViewPackage = () => {
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    // Fetch saved packages from the server when the component mounts
    const fetchCoupons = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_VIEW_PACKAGES);
        console.log('Fetched Coupons:', response.data);
        setCoupons(response.data);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };

    fetchCoupons();

    return () => {
      setCoupons([]);
    };
  }, []);

  const handleRemovePackage = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_DELETE_SUBSCRIPTIONPACKAGE);
      // Remove the deleted coupon from the local state
      setCoupons(prevCoupons => prevCoupons.filter(coupon => coupon.id !== id));
    } catch (error) {
      console.error('Error removing package:', error);
    }
  };

  return (
    <div className="coupon-list">
      <h2>Coupons</h2>
      {coupons.length === 0 ? (
        <p>No packages available</p>
      ) : (
        <div className="coupon-container">
          {coupons.map((coupon) => (
            <div key={coupon.id} className="coupon">
              <div className="coupon-details">
                <h3>{coupon.packageType}</h3>
                <ul className="features-list">
                  {coupon.features.map((feature, index) => (
                    <li key={index}>
                      <span className="feature-check">✔</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="coupon-price">${coupon.price}</div>
              <button className="buy-button">Buy</button>
              <button className="close-button" onClick={() => handleRemovePackage(coupon.id)}>Remove</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ViewPackage;



