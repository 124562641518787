import React, { useState } from "react";
import axios from "axios";
import "./landingStyles.css";

function LandingPage() {
  const [generatedLink, setGeneratedLink] = useState("");
  const [inputValue, setInputValue] = useState("");

  const handleGenerateLink = async () => {
    try {
      console.log("CREATE_LINK_API value:", process.env.REACT_APP_CREATE_LINK_API);
      const response = await axios.post(process.env.REACT_APP_CREATE_LINK_API);
      
      const { newLink } = response.data;
      console.log("Received link:", newLink);
      setGeneratedLink(newLink); // Update state with the received link
      setInputValue(newLink); // Update input field value with the received link
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleJoinLink = async () => {
    window.location.href = inputValue;
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="landing-page">
      <header>
        <img
          src="/images/logo.png"
          alt="ConnectXpert Logo"
          className="logo"
        />
      </header>
      <main>
        <h1>Join or start a meeting</h1>
        <input
          type="text"
          placeholder="Enter meeting link"
          className="meeting-code-input"
          value={inputValue}
          onChange={handleInputChange}
        />
        <button className="join-button" onClick={handleJoinLink}>
          Join
        </button>
        <p>or</p>
        <button className="create-button" onClick={handleGenerateLink}>
          Create a meeting
        </button>
      </main>
      <footer>
        <p className="footer-text">
          © 2024 ConnectXpert LLC. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default LandingPage;
