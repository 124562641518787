import React, { Component } from "react";
import "./Chat.css";

class ParticipantsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  render() {
    return (
      <div className="frame">
        <div className="content">
          <div className="chat-header">
            <div className="chat-heading">Participants</div>
            <div
              className="material-icons"
              id="participants-close-btn"
              onClick={this.handleCloseChat}
            >
              close
            </div>
          </div>
          <div className="participants-container">
            
          </div>
        </div>
      </div>
    );
  }
}

export default ParticipantsTab;
