import React, { useState, useEffect } from "react";
import Message from "../message";
import KeywordGroupedMessages from "./KeywordGroupedMessage";
import { format } from "date-fns";
import nlp from "compromise";
import "../Chat.css";

// const ChatBox = (props) => {
//   const [message, setMessage] = useState("");
//   const [user, setUser] = useState({ uid: 0, username: "" });
//   const [groupedMessages, setGroupedMessages] = useState({});
//   const [allMessages, setAllMessages] = useState([]);

//   const scrollToBottom = () => {
//     const chat = document.getElementById("chatList");
//     chat.scrollTop = chat.scrollHeight;
//   };

//   useEffect(() => {
//     scrollToBottom();
//     setUser({ uid: props.user.uid, username: props.user.username });
//   }, [props]);

//   useEffect(() => {
//     setAllMessages(props.messages);
//     if (props.messages.length > 1) {
//       const grouped = groupMessages(props.messages);
//       setGroupedMessages(grouped);
//     } else {
//       setGroupedMessages({});
//     }
//     scrollToBottom();
//   }, [props.messages]);

//   const sendMessage = (msg) => {
//     props.sendMessage(msg);
//     scrollToBottom();
//   };

//   const handleSubmit = (event) => {
//     if (message === "") return;
//     event.preventDefault();
//     const timestamp = new Date();
//     sendMessage({
//       type: "text",
//       message: {
//         id: user.uid,
//         sender: { uid: user.uid, username: user.username },
//         data: { text: message },
//         timestamp: timestamp,
//       },
//     });
//     setMessage("");
//   };

//   const handleChange = (event) => {
//     setMessage(event.target.value);
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === "Enter") {
//       handleSubmit(event);
//     }
//   };

//   const handleCloseChat = () => {
//     props.handleCloseChat();
//   };

//   const extractKeywords = (text) => {
//     const doc = nlp(text);
//     const keywords = doc
//       .nouns()
//       .not("#Pronoun")
//       .not("#Determiner")
//       .not("#Conjunction")
//       .not("#Preposition")
//       .out("array");
//     return keywords;
//   };

//   const groupMessages = (messages) => {
//     const keywordMap = {};

//     for (const message of messages) {
//       const keywords = extractKeywords(message.message.data.text);

//       for (const keyword of keywords) {
//         if (!keywordMap[keyword]) {
//           keywordMap[keyword] = [];
//         }
//         keywordMap[keyword].push(message);
//       }
//     }

//     // Filter out groups with only one message
//     const filteredKeywordMap = Object.fromEntries(
//       Object.entries(keywordMap).filter(([_, msgs]) => msgs.length > 1)
//     );

//     return filteredKeywordMap;
//   };

//   return (
//     <div id="frame">
//       <div className="content">
//         <div className="chat-header">
//           <div className="chat-heading">Chat</div>
//           <div
//             className="material-icons"
//             id="chat-close-btn"
//             onClick={handleCloseChat}
//           >
//             close
//           </div>
//         </div>
//         <div className="messages">
//           <div className="chat" id="chatList">
//             {allMessages.map((data, index) => (
//               <li key={index}>
//                 <Message
//                   username={data.message.sender.username}
//                   text={data.message.data.text}
//                   time={format(data.message.timestamp, "HH:mm")}
//                   userType={
//                     user.uid === data.message.sender.uid ? "local" : "remote"
//                   }
//                 />
//               </li>
//             ))}
//           </div>
//           <KeywordGroupedMessages groupedMessages={groupedMessages} user={user} />
//         </div>
//         <div className="message-input">
//           <input
//             className="input"
//             type="text"
//             placeholder="Write your message..."
//             onChange={handleChange}
//             onKeyPress={handleKeyPress}
//             value={message}
//           />
//           <button className="send-msg-btn" type="submit" onClick={handleSubmit}>
//             <i className="fa fa-paper-plane" aria-hidden="true"></i>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatBox;

// const ChatBox = (props) => {
//   const [message, setMessage] = useState("");
//   const [user, setUser] = useState({ uid: 0, username: "" });
//   const [groupedMessages, setGroupedMessages] = useState({});
//   const [allMessages, setAllMessages] = useState([]);
//   const [activeTab, setActiveTab] = useState("chat");

//   const scrollToBottom = () => {
//     const chat = document.getElementById("chatList");
//     chat.scrollTop = chat.scrollHeight;
//   };

//   useEffect(() => {
//     scrollToBottom();
//     setUser({ uid: props.user.uid, username: props.user.username });
//   }, [props]);

//   useEffect(() => {
//     setAllMessages(props.messages);
//     if (props.messages.length > 1) {
//       const grouped = groupMessages(props.messages);
//       setGroupedMessages(grouped);
//     } else {
//       setGroupedMessages({});
//     }
//     scrollToBottom();
//   }, [props.messages]);

//   const sendMessage = (msg) => {
//     props.sendMessage(msg);
//     scrollToBottom();
//   };

//   const handleSubmit = (event) => {
//     if (message === "") return;
//     event.preventDefault();
//     const timestamp = new Date();
//     sendMessage({
//       type: "text",
//       message: {
//         id: user.uid,
//         sender: { uid: user.uid, username: user.username },
//         data: { text: message },
//         timestamp: timestamp,
//       },
//     });
//     setMessage("");
//   };

//   const handleChange = (event) => {
//     setMessage(event.target.value);
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === "Enter") {
//       handleSubmit(event);
//     }
//   };

//   const handleCloseChat = () => {
//     props.handleCloseChat();
//   };

//   const extractKeywords = (text) => {
//     const doc = nlp(text);
//     const keywords = doc
//       .nouns()
//       .not("#Pronoun")
//       .not("#Determiner")
//       .not("#Conjunction")
//       .not("#Preposition")
//       .out("array");
//     return keywords;
//   };

//   const groupMessages = (messages) => {
//     const keywordMap = {};

//     for (const message of messages) {
//       const keywords = extractKeywords(message.message.data.text);

//       for (const keyword of keywords) {
//         if (!keywordMap[keyword]) {
//           keywordMap[keyword] = [];
//         }
//         keywordMap[keyword].push(message);
//       }
//     }

//     // Filter out groups with only one message
//     const filteredKeywordMap = Object.fromEntries(
//       Object.entries(keywordMap).filter(([_, msgs]) => msgs.length > 1)
//     );

//     return filteredKeywordMap;
//   };

//   return (
//     <div id="frame">
//       <div className="content">
//         <div className="chat-header">
//           <div className="chat-heading">Chat</div>
//           <div
//             className="material-icons"
//             id="chat-close-btn"
//             onClick={handleCloseChat}
//           >
//             close
//           </div>
//         </div>
//         <div className="tab-nav">
//           <button
//             className={activeTab === "chat" ? "active" : ""}
//             onClick={() => setActiveTab("chat")}
//           >
//             Chat
//           </button>
//           <button
//             className={activeTab === "keywords" ? "active" : ""}
//             onClick={() => setActiveTab("keywords")}
//           >
//             Keywords
//           </button>
//         </div>
//         <div className="messages">
//           {activeTab === "chat" && (
//             <div className="chat" id="chatList">
//               {allMessages.map((data, index) => (
//                 <li key={index}>
//                   <Message
//                     username={data.message.sender.username}
//                     text={data.message.data.text}
//                     time={format(data.message.timestamp, "HH:mm")}
//                     userType={
//                       user.uid === data.message.sender.uid ? "local" : "remote"
//                     }
//                   />
//                 </li>
//               ))}
//             </div>
//           )}
//           {activeTab === "keywords" && (
//             <KeywordGroupedMessages
//               groupedMessages={groupedMessages}
//               user={user}
//             />
//           )}
//         </div>
//         <div className="message-input">
//           <input
//             className="input"
//             type="text"
//             placeholder="Write your message..."
//             onChange={handleChange}
//             onKeyPress={handleKeyPress}
//             value={message}
//           />
//           <button className="send-msg-btn" type="submit" onClick={handleSubmit}>
//             <i className="fa fa-paper-plane" aria-hidden="true"></i>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatBox;
// -------------------------------------WITH TABS ---------------------
// const ChatBox = (props) => {
//   const [message, setMessage] = useState("");
//   const [user, setUser] = useState({ uid: 0, username: "" });
//   const [groupedMessages, setGroupedMessages] = useState({});
//   const [allMessages, setAllMessages] = useState([]);
//   const [activeTab, setActiveTab] = useState("chat");

//   const scrollToBottom = () => {
//     const chat = document.getElementById("chatList");
//     if (chat) {
//       chat.scrollTop = chat.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     scrollToBottom();
//     setUser({ uid: props.user.uid, username: props.user.username });
//   }, [props]);

//   useEffect(() => {
//     setAllMessages(props.messages);
//     if (props.messages.length > 1) {
//       const grouped = groupMessages(props.messages);
//       setGroupedMessages(grouped);
//     } else {
//       setGroupedMessages({});
//     }
//     scrollToBottom();
//   }, [props.messages]);

//   const sendMessage = (msg) => {
//     props.sendMessage(msg);
//     scrollToBottom();
//   };

//   const handleSubmit = (event) => {
//     if (message === "") return;
//     event.preventDefault();
//     const timestamp = new Date();
//     sendMessage({
//       type: "text",
//       message: {
//         id: user.uid,
//         sender: { uid: user.uid, username: user.username },
//         data: { text: message },
//         timestamp: timestamp,
//       },
//     });
//     setMessage("");
//   };

//   const handleChange = (event) => {
//     setMessage(event.target.value);
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === "Enter") {
//       handleSubmit(event);
//     }
//   };

//   const handleCloseChat = () => {
//     props.handleCloseChat();
//   };

//   const extractKeywords = (text) => {
//     const doc = nlp(text);
//     const keywords = doc
//       .nouns()
//       .not("#Pronoun")
//       .not("#Determiner")
//       .not("#Conjunction")
//       .not("#Preposition")
//       .out("array");
//     return keywords;
//   };

//   const groupMessages = (messages) => {
//     const keywordMap = {};

//     for (const message of messages) {
//       const keywords = extractKeywords(message.message.data.text);

//       for (const keyword of keywords) {
//         if (!keywordMap[keyword]) {
//           keywordMap[keyword] = [];
//         }
//         keywordMap[keyword].push(message);
//       }
//     }

//     // Filter out groups with only one message
//     const filteredKeywordMap = Object.fromEntries(
//       Object.entries(keywordMap).filter(([_, msgs]) => msgs.length > 1)
//     );

//     return filteredKeywordMap;
//   };

//   return (
//     <div id="frame">
//       <div className="content">
//         <div className="chat-header">
//           <div className="chat-heading">Chat</div>
//           <div
//             className="material-icons"
//             id="chat-close-btn"
//             onClick={handleCloseChat}
//           >
//             close
//           </div>
//         </div>
//         <div className="tab-nav">
//           <button
//             className={activeTab === "chat" ? "active" : ""}
//             onClick={() => setActiveTab("chat")}
//           >
//             Public Messages
//           </button>
//           <button
//             className={activeTab === "keywords" ? "active" : ""}
//             onClick={() => setActiveTab("keywords")}
//           >
//             Compiled Messages
//           </button>
//           <button
//             className={activeTab === "attendees" ? "active" : ""}
//             onClick={() => setActiveTab("attendees")}
//           >
//             Participants
//           </button>
//         </div>
//         <div className="tab-content">
//           {activeTab === "chat" && (
//             <div className="chat" id="chatList">
//               {allMessages.map((data, index) => (
//                 <li key={index}>
//                   <Message
//                     username={data.message.sender.username}
//                     text={data.message.data.text}
//                     time={format(data.message.timestamp, "HH:mm")}
//                     userType={
//                       user.uid === data.message.sender.uid ? "local" : "remote"
//                     }
//                   />
//                 </li>
//               ))}
//             </div>
//           )}
//           {activeTab === "keywords" && (
//             <KeywordGroupedMessages
//               groupedMessages={groupedMessages}
//               user={user}
//             />
//           )}
//           {activeTab === "attendees" && (
//             <div className="attendees">
//               {/* Render participants here */}
//               <p>Participants list goes here...</p>
//             </div>
//           )}
//         </div>
//         {activeTab === "chat" && (
//           <div className="message-input">
//             <input
//               className="input"
//               type="text"
//               placeholder="Write your message..."
//               onChange={handleChange}
//               onKeyPress={handleKeyPress}
//               value={message}
//             />
//             <button className="send-msg-btn" type="submit" onClick={handleSubmit}>
//               <i className="fa fa-paper-plane" aria-hidden="true"></i>
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ChatBox;

const ChatBox = (props) => {
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({ uid: 0, username: "" });
  const [groupedMessages, setGroupedMessages] = useState({});
  const [allMessages, setAllMessages] = useState([]);
  const [activeTab, setActiveTab] = useState("chat");

  const scrollToBottom = () => {
    const chat = document.getElementById("chatList");
    if (chat) {
      chat.scrollTop = chat.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    setUser({ uid: props.user.uid, username: props.user.username });
  }, [props]);

  useEffect(() => {
    setAllMessages(props.messages);
    if (props.messages.length > 1) {
      const grouped = groupMessages(props.messages);
      setGroupedMessages(grouped);
    } else {
      setGroupedMessages({});
    }
    scrollToBottom();
  }, [props.messages]);

  const sendMessage = (msg) => {
    props.sendMessage(msg);
    scrollToBottom();
  };

  const handleSubmit = (event) => {
    if (message === "") return;
    event.preventDefault();
    const timestamp = new Date();
    sendMessage({
      type: "text",
      message: {
        id: user.uid,
        sender: { uid: user.uid, username: user.username },
        data: { text: message },
        timestamp: timestamp,
      },
    });
    setMessage("");
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleCloseChat = () => {
    props.handleCloseChat();
  };

  const extractKeywords = (text) => {
    const doc = nlp(text);
    const keywords = doc
      .nouns()
      .not("#Pronoun")
      .not("#Determiner")
      .not("#Conjunction")
      .not("#Preposition")
      .out("array");
    return keywords;
  };

  const groupMessages = (messages) => {
    const keywordMap = {};

    for (const message of messages) {
      const keywords = extractKeywords(message.message.data.text);

      for (const keyword of keywords) {
        if (!keywordMap[keyword]) {
          keywordMap[keyword] = [];
        }
        keywordMap[keyword].push(message);
      }
    }

    // Filter out groups with only one message
    const filteredKeywordMap = Object.fromEntries(
      Object.entries(keywordMap).filter(([_, msgs]) => msgs.length > 1)
    );

    return filteredKeywordMap;
  };

  return (
    <div className="frame">
      <div className="content">
        <div className="tab-nav">
          <button
            className={activeTab === "chat" ? "active" : ""}
            onClick={() => setActiveTab("chat")}
          >
            Public Messages
          </button>
          <button
            className={activeTab === "keywords" ? "active" : ""}
            onClick={() => setActiveTab("keywords")}
          >
            Compiled Messages
          </button>
          {/* <button
            className={activeTab === "attendees" ? "active" : ""}
            onClick={() => setActiveTab("attendees")}
          >
            Participants
          </button> */}
          {/* <span className="close-icon" onClick={handleCloseChat}>&times;</span> */}
          <div
            className="material-icons"
            id="chat-close-btn"
            onClick={handleCloseChat}
          >
            close
          </div>
        </div>
        <div className="tab-content">
          {activeTab === "chat" && (
            <div className="chat" id="chatList">
              {allMessages.map((data, index) => (
                <li key={index}>
                  <Message
                    username={data.message.sender.username}
                    text={data.message.data.text}
                    time={format(data.message.timestamp, "HH:mm")}
                    userType={
                      user.uid === data.message.sender.uid ? "local" : "remote"
                    }
                  />
                </li>
              ))}
            </div>
          )}
          {activeTab === "keywords" && (
            <KeywordGroupedMessages
              groupedMessages={groupedMessages}
              user={user}
            />
          )}
          {activeTab === "attendees" && (
            <div className="attendees">
              {/* Render participants here */}
              <p>Participants list goes here...</p>
            </div>
          )}
        </div>
        {activeTab === "chat" && (
          <div className="message-input">
            <input
              className="input"
              type="text"
              placeholder="Write your message..."
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              value={message}
            />
            <button className="send-msg-btn" type="submit" onClick={handleSubmit}>
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBox;
