import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreatePackage.css';

const CreatePackage = () => {
  const [packageType, setPackageType] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [price, setPrice] = useState(0);
  const [featuresList, setFeaturesList] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_FETCH_FEATURES);
        if (response.data && Array.isArray(response.data)) {
          setFeaturesList(response.data);
        } else {
          console.error('Invalid response format for features:', response.data);
        }
      } catch (error) {
        console.error('Error fetching features:', error);
      }
    };

    fetchFeatures();

    return () => {
      setFeaturesList([]);
    };
  }, []);

  const handleFeatureChange = (featureName) => {
    setSelectedFeatures((prevSelectedFeatures) => {
      if (prevSelectedFeatures.includes(featureName)) {
        return prevSelectedFeatures.filter((selectedFeature) => selectedFeature !== featureName);
      } else {
        return [...prevSelectedFeatures, featureName];
      }
    });
  };

  const getFeatureIds = async () => {
    try {
      const promises = selectedFeatures.map(async (featureName) => {
        const response = await axios.get(process.env.REACT_APP_GET_FEATURES_BY_NAME);
        return response.data.id; // Assuming the API returns an object with an "id" property
      });
      return await Promise.all(promises);
    } catch (error) {
      console.error('Error fetching feature IDs:', error);
      return [];
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const packageResponse = await axios.post(process.env.REACT_APP_ADD_PACKAGE, {
        packageType,
        price,
      });
      const packageId = packageResponse.data; // Assuming the API returns the new package ID

      const featureIds = await getFeatureIds();
      await axios.post(process.env.REACT_APP_CREATE_SUBSCRIPTIONPACKAGE_API, {
        packageId,
        featureIds,
      });

      console.log('New package and subscription package saved successfully');

      setPackageType('');
      setSelectedFeatures([]);
      setPrice(0);
    } catch (error) {
      console.error('Error saving package:', error);
      if (error.response && error.response.data) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  return (
    <div className="create-package-container">
      <h1>Create New Package</h1>
      <form onSubmit={handleSave}>
        <label>
          Package Type:
          <input
            type="text"
            value={packageType}
            onChange={(e) => setPackageType(e.target.value)}
            className="package-input"
          />
        </label>
        <label>
          Price:
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="price-input"
          />
        </label>
        <h2>Features:</h2>
        <table className="features-table">
          <tbody>
            {featuresList.map((feature) => (
              <tr key={feature.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedFeatures.includes(feature.featureName)}
                    onChange={() => handleFeatureChange(feature.featureName)}
                    className="include-checkbox"
                  />
                </td>
                <td>{feature.featureName}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit" className="save-button">
          Save
        </button>
      </form>
    </div>
  );
};

export default CreatePackage;