import React, { Component } from "react";

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mic: true,
      camera: true,
      // currentStream: new MediaStream(),
      // videoTrack: false,
      videoVisible: true,
    };
  }

  componentDidMount() {
    if (this.props.videoStream) {
      this.video.srcObject = this.props.videoStream;
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('1. nextProps', this.props.showMuteControls, nextProps.videoStream && nextProps.videoStream.getTracks())
    console.log("1", this.props.videoType, nextProps.videoStream);

    // This is done only once
    if (
      nextProps.videoStream &&
      nextProps.videoStream !== this.props.videoStream
    ) {
      // if (!this.props.videoStream) {
      console.log("2", this.props.videoType, nextProps.videoStream);
      this.video.srcObject = nextProps.videoStream;
    }

    // This is done only once when we receive a video track
    const videoTrack =
      nextProps.videoStream && nextProps.videoStream.getVideoTracks();
    if (
      this.props.videoType === "remoteVideo" &&
      videoTrack &&
      videoTrack.length
    ) {
      videoTrack[0].onmute = () => {
        // alert('muted')
        this.setState({
          videoVisible: false,
        });
        this.props.videoMuted(nextProps.videoStream);
      };

      videoTrack[0].onunmute = () => {
        this.setState({
          videoVisible: true,
        });
        this.props.videoMuted(nextProps.videoStream);
      };
    }

    const audioTrack =
      nextProps.videoStream && nextProps.videoStream.getAudioTracks();
    if (
      this.props.videoType === "remoteVideo" &&
      audioTrack &&
      audioTrack.length
    ) {
      audioTrack[0].onmute = () => {
        alert("muted");
        // this.setState({
        //   videoVisible: false,
        // })
        // this.props.videoMuted(nextProps.videoStream)
      };
    }
  }

 
  render() {
   
    return (
      <div style={{ ...this.props.frameStyle }} className={this.props.class}>
        {/* <audio id={this.props.id} muted={this.props.muted} ref={ (ref) => {this.video = ref }}></audio> */}
        <video
          id={this.props.id}
          muted={this.props.muted}
          autoPlay
          style={{
            visibility: (this.state.videoVisible && "visible") || "hidden",
            ...this.props.videoStyles,
          }}
          // ref={ this.props.videoRef }
          ref={(ref) => {
            this.video = ref;
          }}
        ></video>
     
      </div>
    );
  }
}

export default Video;
