import React from 'react'

export default function EmojisFlying(props) {
  return (
    <div className="emojis">
      {props.emojis.map((emoji, index) => (
        <span key={index} className="emoji emoticon-flying">
          {`${emoji.sender}: ${emoji.emoji}`}
        </span>
      ))}
    </div>
  )
}
