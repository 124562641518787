
import React from "react";
import "./message.css";

function Message(props) {
  const username = props.username;
  console.log(username);
  // const username = "username";
  const isLocalUser = props.userType === "local";

  return (
    <div className="msg-flex-col">
      {/* <div className={`msg-info-container ${isLocalUser ? "left-msg" : "right-msg"}`}> */}
       <div className={`msg-info-container`}>
        <div className="msg-info-name">{username}</div>
        <div className="msg-info-time">{props.time}</div>
      </div>
      <div className={`msg-container ${isLocalUser ? "local-msg" : "remote-msg"}`}>
        <p className="msg-text">{props.text}</p>
      </div>
    </div>
  );
}

export default Message;
