import React, { useState } from 'react';
import './AddFeature.css';
import axios from 'axios';

function AddFeature() {
  const [featureName, setFeatureName] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (event) => {
    setFeatureName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!featureName.trim()) {
      setMessage('Please enter a feature name');
      return;
    }

    try {
      const response = await axios.post('/addfeatures', { featureName });

      if (response.status === 200) {
        setMessage('Feature added successfully');
        setFeatureName(''); // Clear input field
      } else {
        setMessage('Error adding feature');
      }
    } catch (error) {
      console.error('Error adding feature:', error);
      setMessage('Error adding feature');
    }

    // Clear the message after a delay
    setTimeout(() => setMessage(''), 3000);
  };

  return (
    <div className="container">
      <h1>Add Feature</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={featureName}
          onChange={handleChange}
          placeholder="Enter feature name"
        />
        <button className ="Feature-Add-Btn" type="submit">Add Feature</button>
      </form>
      <p className={message ? 'message' : ''}>{message}</p>
    </div>
  );
}

export default AddFeature;
