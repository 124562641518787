import React from "react";
import Message from "../message";
import { format } from "date-fns";

const KeywordGroupedMessages = ({ groupedMessages, user }) => {
  return (
    <div className="keyword-grouped-messages">
      {Object.keys(groupedMessages).length > 0 && (
        <div>
          {Object.keys(groupedMessages).map((keyword, index) => (
            <div key={index} className="keyword-group">
              <strong>Keyword: {keyword}</strong>
              <div className="group-messages-container">
                {groupedMessages[keyword].map((data, idx) => (
                  <div key={idx}>
                    <Message
                      username={data.message.sender.username}
                      text={data.message.data.text}
                      time={format(data.message.timestamp, "HH:mm")}
                      userType={
                        user.uid === data.message.sender.uid ? "local" : "remote"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default KeywordGroupedMessages;
