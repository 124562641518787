import React, { Component } from "react";
import io from "socket.io-client";
import axios from "axios";
import { v4 as uuidv4 } from "uuid"; // Import UUID library
import Video from "./video";
import Videos from "./videos";
import { useParams } from "react-router-dom";

// import ChatBoxReact from "./chatBoxReact";
import ChatBox from "../components/Chat/ChatBox";

import CallControls from "./callControls";
import EmojisFlying from "./emojisFlying";
import "../App.css";
import ParticipantsTab from "./participantsTab";
class Call extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localStream: null, // used to hold local stream object to avoid recreating the stream everytime a new offer comes
      remoteStream: null, // used to hold remote stream object that is displayed in the main screen

      remoteStreams: [], // holds all Video Streams (all remote streams)
      peerConnections: {}, // holds all Peer Connections
      roomID: "", // New state property to hold the generated room ID
      meetingLink: "", // New state property to hold the meeting link with room
      showChatBox: false,
      showParticipantsTab: false,
      activeTab: null,
      senders: [],
      selectedVideo: null,
      emojis: [],
      status: "Please wait...",
      participantsCount: 0,
      // room: this.props.match.params.room,
      participants: [],

      pc_config: {
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
        ],
      },

      sdpConstraints: {
        mandatory: {
          OfferToReceiveAudio: true,
          OfferToReceiveVideo: true,
        },
      },

      messages: [],
      sendChannels: [],
      disconnected: false,
    };

    // DON'T FORGET TO CHANGE TO YOUR URL
    this.serviceIP = process.env.REACT_APP_CLIENT_IP;

    this.socket = null;
  }

  getLocalStream = () => {
    const success = (stream) => {
      window.localStream = stream;
      this.setState({
        localStream: stream,
      });

      this.whoisOnline();
    };
    const failure = (e) => {
      console.log("getUserMedia Error: ", e);
    };

    const constraints = {
      audio: true,
      video: true,
      options: {
        mirror: false,
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(success)
      .catch(failure);
  };

  getScreenShare = () => {
    let senders = this.state.senders;

    const constraints = {
      audio: true,
      video: true,
      options: {
        mirror: true,
      },
    };

    navigator.mediaDevices
      .getDisplayMedia({
        video: {
          cursor: "always",
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
      })
      .then((stream) => {
        let videoStream = stream.getVideoTracks();
        console.log("Display media successfully accessed.");

        let sender = senders.find(function (s) {
          return s.track.kind === videoStream[0].kind;
        });

        if (sender) {
          console.log("Sender found. Attempting to replace track.");
          sender.replaceTrack(videoStream[0]);
          console.log("Track replaced successfully.");
        } else {
          console.log("Sender not found or not properly initialized.");
        }

        // Listen for when the screen sharing stops
        stream.getVideoTracks()[0].onended = () => {
          // Get the user's camera stream
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((cameraStream) => {
              // Replace the shared screen stream with the camera stream
              sender.replaceTrack(cameraStream.getVideoTracks()[0]);
              console.log("Returned to camera stream successfully.");
            })
            .catch((err) => {
              console.log("Error accessing camera stream:", err);
            });
        };
      })
      .catch((err) => {
        console.log("Error accessing display media:", err);
      });
  };

  whoisOnline = () => {
    // let all peers know I am joining
    this.sendToPeer("onlinePeers", null, { local: this.socket.id });
  };

  sendToPeer = (messageType, payload, socketID) => {
    this.socket.emit(messageType, {
      socketID,
      payload,
    });
  };

  createPeerConnection = (socketID, callback) => {
    try {
      let pc = new RTCPeerConnection(this.state.pc_config);

      // add pc to peerConnections object
      const peerConnections = { ...this.state.peerConnections, [socketID]: pc };
      this.setState({
        peerConnections,
      });

      pc.onicecandidate = (e) => {
        if (e.candidate) {
          this.sendToPeer("candidate", e.candidate, {
            local: this.socket.id,
            remote: socketID,
          });
        }
      };

      pc.oniceconnectionstatechange = (e) => {
        // if (pc.iceConnectionState === 'disconnected') {
        //   const remoteStreams = this.state.remoteStreams.filter(stream => stream.id !== socketID)
        //   this.setState({
        //     remoteStream: remoteStreams.length > 0 && remoteStreams[0].stream || null,
        //   })
        // }
      };

      pc.ontrack = (e) => {
        let _remoteStream = null;
        let remoteStreams = this.state.remoteStreams;
        let remoteVideo = {};

        // 1. check if stream already exists in remoteStreams
        const rVideos = this.state.remoteStreams.filter(
          (stream) => stream.id === socketID
        );

        // 2. if it does exist then add track
        if (rVideos.length) {
          _remoteStream = rVideos[0].stream;
          this.state.senders.push(
            _remoteStream.addTrack(e.track, _remoteStream)
          );

          remoteVideo = {
            ...rVideos[0],
            stream: _remoteStream,
          };
          remoteStreams = this.state.remoteStreams.map((_remoteVideo) => {
            return (
              (_remoteVideo.id === remoteVideo.id && remoteVideo) ||
              _remoteVideo
            );
          });
        } else {
          // 3. if not, then create new stream and add track
          _remoteStream = new MediaStream();
          this.state.senders.push(
            _remoteStream.addTrack(e.track, _remoteStream)
          );

          remoteVideo = {
            id: socketID,
            name: socketID,
            stream: _remoteStream,
          };
          remoteStreams = [...this.state.remoteStreams, remoteVideo];
        }

        this.setState((prevState) => {
          // If we already have a stream in display let it stay the same, otherwise use the latest stream
          // const remoteStream = prevState.remoteStreams.length > 0 ? {} : { remoteStream: e.streams[0] }
          const remoteStream =
            prevState.remoteStreams.length > 0
              ? {}
              : { remoteStream: _remoteStream };

          // get currently selected video
          let selectedVideo = prevState.remoteStreams.filter(
            (stream) => stream.id === prevState.selectedVideo.id
          );
          // if the video is still in the list, then do nothing, otherwise set to new video stream
          selectedVideo = selectedVideo.length
            ? {}
            : { selectedVideo: remoteVideo };

          return {
            ...selectedVideo,
            ...remoteStream,
            remoteStreams,
          };
        });
      };

      pc.close = () => {
        console.log("pc closed");
      };

      if (this.state.localStream)
        this.state.localStream.getTracks().forEach((track) => {
          this.state.senders.push(pc.addTrack(track, this.state.localStream));
        });

      callback(pc);
    } catch (e) {
      console.log("Something went wrong! pc not created!!", e);
      callback(null);
    }
  };

  // Initialize socket event listeners
  componentDidMount = () => {
    // Extract room ID from the URL
    const roomIDFromURL = window.location.pathname.substring(1);
    console.log("room id from url : " + roomIDFromURL);

    if (roomIDFromURL) {
      if (roomIDFromURL !== this.state.roomID) {
        this.setState({ roomID: roomIDFromURL }, () => {
          console.log("State updated:", this.state.roomID);
          this.connectToSocket();
        });
      } else {
        console.log("State already up to date:", this.state.roomID);
        this.connectToSocket();
      }
    } else {
      const newRoomID = uuidv4();
      this.setState({ roomID: newRoomID }, () => {
        console.log("State updated:", this.state.roomID);
        this.connectToSocket();
      });
      const newURL = `/${newRoomID}`;
      window.history.replaceState({}, "", newURL);
    }
    // Retrieve username from localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.username) {
      this.setState({ username: userData.username });
    }
    // Fetch participants for the current room
    this.fetchParticipants();
  };
  fetchParticipants = async () => {
    try {
      const response = await axios.get(`/participants/${this.state.roomID}`);
      this.setState({ participants: response.data });
      console.log("Participants:", this.state.participants);
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  };
  connectToSocket = () => {
    console.log("Connecting to socket with room ID:", this.state.roomID);
    this.socket = io.connect(this.serviceIP, {
      path: "/io/webrtc",
      query: {
        room: this.state.roomID,
      },
    });

    // Initialize socket event listeners
    this.initializeSocketEvents({
      "connection-success": this.handleConnectionSuccess,
      "joined-peers": this.handleJoinedPeers,
      "peer-disconnected": this.handlePeerDisconnected,
      "online-peer": this.handleOnlinePeer,
      "participantsUpdated" : this.participantsUpdated,
      offer: this.handleOffer,
      answer: this.handleAnswer,
      candidate: this.handleCandidate,
    });
  };

  initializeSocketEvents(callbacks) {
    Object.entries(callbacks).forEach(([event, handler]) => {
      this.socket.on(event, handler);
    });
  }
  participantsUpdated = (participants) => {
    console.log("Received participants:", participants);
    // Update the state or perform any necessary actions with the received participants list
    this.setState({ participants: participants });
  };

  handleConnectionSuccess = (data) => {
    // Handle the event when connection is successful
    this.getLocalStream();
    this.setState({
      participantsCount: data.peerCount,
    });
    const status =
      data.peerCount > 1
        ? `Total Connected Peers to room ${window.location.pathname}: ${data.peerCount}`
        : "Waiting for other peers to connect";

    this.setState({
      status: status,
      messages: data.messages,
    });
  };

  handleJoinedPeers = (data) => {
    // Handle the event when peers join the room
    console.log("Total Connected Peers to room" + data.peerCount);
    this.setState({
      participantsCount: data.peerCount,
    });
    console.log("Total Connected particiapnts" + this.state.participantsCount);
    this.setState({
      status:
        data.peerCount > 1
          ? `Total Connected Peers to room ${window.location.pathname}: ${data.peerCount}`
          : "Waiting for other peers to connect",
    });
  };

  handlePeerDisconnected = (data) => {
    // Close peer-connection with the disconnected peer
    this.state.peerConnections[data.socketID].close();

    // Stop remote audio and video tracks of the disconnected peer
    const rVideo = this.state.remoteStreams.filter(
      (stream) => stream.id === data.socketID
    );
    rVideo && this.stopTracks(rVideo[0].stream);

    // Filter out the disconnected peer stream
    const remoteStreams = this.state.remoteStreams.filter(
      (stream) => stream.id !== data.socketID
    );

    this.setState((prevState) => ({
      // Update the participant count by decrementing it
      participantsCount: prevState.participantsCount - 1,
      remoteStreams,
      // Update the status message accordingly
      status:
        prevState.participantsCount > 1
          ? `Total Connected Peers to room ${window.location.pathname}: ${
              prevState.participantsCount - 1
            }`
          : "Waiting for other peers to connect",
    }));
  };

  handleOnlinePeer = (socketID) => {
    // Handle the event when a peer comes online
    // Create new peer connection
    this.createPeerConnection(socketID, (pc) => {
      // Create Offer
      if (pc) {
        // Send Channel
        const handleSendChannelStatusChange = (event) => {
          console.log(
            "send channel status: " + this.state.sendChannels[0].readyState
          );
        };

        const sendChannel = pc.createDataChannel("sendChannel");
        sendChannel.onopen = handleSendChannelStatusChange;
        sendChannel.onclose = handleSendChannelStatusChange;

        this.setState((prevState) => {
          return {
            sendChannels: [...prevState.sendChannels, sendChannel],
          };
        });
        // Function to handle receiving messages from data channel
        const handleReceiveMessage = (event) => {
          const message = event.data;

          console.log(message);
          try {
            const parsedMessage = JSON.parse(message);
            // If the message is valid JSON, handle it as JSON
            this.setState((prevState) => ({
              messages: [...prevState.messages, parsedMessage],
            }));
          } catch (error) {
            // If parsing as JSON fails, handle it as an emoji
            this.handleReceivedEmoji(message);
            // this.sendEmojiToRemote(message);
          }
        };

        const handleReceiveChannelStatusChange = (event) => {
          if (this.receiveChannel) {
            console.log(
              "receive channel's status has changed to " +
                this.receiveChannel.readyState
            );
          }
        };

        const receiveChannelCallback = (event) => {
          const receiveChannel = event.channel;
          receiveChannel.onmessage = handleReceiveMessage;
          receiveChannel.onopen = handleReceiveChannelStatusChange;
          receiveChannel.onclose = handleReceiveChannelStatusChange;
        };

        pc.ondatachannel = receiveChannelCallback;

        pc.createOffer(this.state.sdpConstraints).then((sdp) => {
          pc.setLocalDescription(sdp);

          this.sendToPeer("offer", sdp, {
            local: this.socket.id,
            remote: socketID,
          });
        });
      }
    });
  };

  handleOffer = (data) => {
    this.createPeerConnection(data.socketID, (pc) => {
      pc.addStream(this.state.localStream);

      // Send Channel
      const handleSendChannelStatusChange = (event) => {
        console.log(
          "send channel status: " + this.state.sendChannels[0].readyState
        );
      };

      const sendChannel = pc.createDataChannel("sendChannel");
      sendChannel.onopen = handleSendChannelStatusChange;
      sendChannel.onclose = handleSendChannelStatusChange;

      this.setState((prevState) => {
        return {
          sendChannels: [...prevState.sendChannels, sendChannel],
        };
      });

      const handleReceiveMessage = (event) => {
        const message = event.data;

        console.log(message);
        try {
          const parsedMessage = JSON.parse(message);
          // If the message is valid JSON, handle it as JSON
          this.setState((prevState) => ({
            messages: [...prevState.messages, parsedMessage],
          }));
        } catch (error) {
          // If parsing as JSON fails, handle it as an emoji
          this.handleReceivedEmoji(message);
          // this.sendEmojiToRemote(message);
        }
      };

      const handleReceiveChannelStatusChange = (event) => {
        if (this.receiveChannel) {
          console.log(
            "receive channel's status has changed to " +
              this.receiveChannel.readyState
          );
        }
      };

      const receiveChannelCallback = (event) => {
        const receiveChannel = event.channel;
        receiveChannel.onmessage = handleReceiveMessage;
        receiveChannel.onopen = handleReceiveChannelStatusChange;
        receiveChannel.onclose = handleReceiveChannelStatusChange;
      };

      pc.ondatachannel = receiveChannelCallback;

      pc.setRemoteDescription(new RTCSessionDescription(data.sdp)).then(() => {
        // 2. Create Answer
        pc.createAnswer(this.state.sdpConstraints).then((sdp) => {
          pc.setLocalDescription(sdp);

          this.sendToPeer("answer", sdp, {
            local: this.socket.id,
            remote: data.socketID,
          });
        });
      });
    });
  };

  handleAnswer = (data) => {
    // get remote's peerConnection
    const pc = this.state.peerConnections[data.socketID];
    // console.log(data.sdp)
    pc.setRemoteDescription(new RTCSessionDescription(data.sdp)).then(() => {});
  };

  handleCandidate = (data) => {
    // get remote's peerConnection
    const pc = this.state.peerConnections[data.socketID];

    if (pc) pc.addIceCandidate(new RTCIceCandidate(data.candidate));
  };

  handleDisconnect = () => {
    // Stop local audio & video tracks
    this.stopTracks(this.state.localStream);

    // Stop all remote audio & video tracks
    this.state.remoteStreams.forEach((rVideo) =>
      this.stopTracks(rVideo.stream)
    );

    // Stop all remote peerconnections
    this.state.peerConnections &&
      Object.values(this.state.peerConnections).forEach((pc) => pc.close());

    // Set the disconnected state to true
    this.setState({ disconnected: true });
  };

  handleMicClick = () => {
    this.setState(
      (prevState) => ({
        mic: !prevState.mic,
      }),
      () => {
        // Update mic status in localStream
        const localStream = this.state.localStream;
        if (localStream) {
          localStream.getAudioTracks().forEach((track) => {
            track.enabled = this.state.mic;
          });
        }
      }
    );
  };

  handleCameraClick = () => {
    this.setState(
      (prevState) => ({
        camera: !prevState.camera,
      }),
      () => {
        // Update camera status in localStream
        const localStream = this.state.localStream;
        if (localStream) {
          localStream.getVideoTracks().forEach((track) => {
            track.enabled = this.state.camera;
          });
        }
      }
    );
  };

  // Function to send emoji to all remote participants
  sendEmojiToRemote = (emoji) => {
    const dataChannels = this.state.sendChannels;
    console.log("inside app.js send emoji to remote", emoji);
    dataChannels.forEach((dataChannel) => {
      if (dataChannel.readyState === "open") {
        dataChannel.send(emoji);
      }
    });
    this.setState((prevState) => ({
      emojis: [...prevState.emojis, { sender: "You", emoji }],
    }));
  };
  // Function to handle receiving emojis from remote participants
  handleReceivedEmoji = (emoji) => {
    this.setState((prevState) => ({
      emojis: [...prevState.emojis, { sender: "Remote", emoji }],
    }));
  };

  // Function to toggle the chat box
  toggleChatBox = () => {
    this.setState((prevState) => ({
      showChatBox: !prevState.showChatBox,
    }));
  };
  // Function to toggle the Participants box
  toggleTabs = () => {
    this.setState((prevState) => ({
      showParticipantsTab: !prevState.showParticipantsTab,
    }));
  };

  toggleActiveTab = (tab) => {
    this.setState((prevState) => ({
      activeTab: prevState.activeTab === tab ? null : tab,
    }));
  };
  // showParticipantsTab

  // ************************************* //
  // NOT REQUIRED
  // ************************************* //

  disconnectSocket = (socketToDisconnect) => {
    this.sendToPeer("socket-to-disconnect", null, {
      local: this.socket.id,
      remote: socketToDisconnect,
    });
  };

  switchVideo = (_video) => {
    this.setState({
      selectedVideo: _video,
    });
  };

  stopTracks = (stream) => {
    stream.getTracks().forEach((track) => track.stop());
  };

  render() {
    const {
      status,
      messages,
      disconnected,
      localStream,
      peerConnections,
      remoteStreams,
      emojis,
      showChatBox,
      showParticipantsTab,
      username,
      activeTab,
    } = this.state;
    // const { username } = this.props;
    console.log("Received username in call login:", username);
    if (disconnected) {
      // disconnect socket
      this.socket.close();
      // stop local audio & video tracks
      this.stopTracks(localStream);

      // stop all remote audio & video tracks
      remoteStreams.forEach((rVideo) => this.stopTracks(rVideo.stream));

      // stop all remote peerconnections
      peerConnections &&
        Object.values(peerConnections).forEach((pc) => pc.close());

      return <div>You have successfully Disconnected</div>;
    }

    const statusText = (
      <div style={{ color: "yellow", padding: 5 }}>{status}</div>
    );
    // has app container

    // Conditionally render the sidebar only if remoteStreams has at least one element
    const sidebar = remoteStreams.length > 0 && (
      <div className="sidebar">
        <Videos switchVideo={this.switchVideo} remoteStreams={remoteStreams} />
      </div>
    );
    return (
      <div
        className="app-container"
        style={{
          display: "flex",
          gridTemplateColumns: "1fr 1fr", // Display columns side by side
          gap: "10px", // Gap between grid items
          backgroundColor: "#1B7A87",
          height: "100vh",
          alignItems: "center",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <div></div>
        <Video
          videoType="localVideo"
          videoStyles={{
            width: 500,
            border: "1px solid yellow",
            display: "block",
            margin: "auto",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          frameStyle={{
            width: 500,
            margin: 5,
            borderRadius: 5,
            backgroundColor: "Red",
            border: "2px solid white",
            width: "70%",
            margin: "auto",
            height: "80%",
            borderRadius: "5px",
            marginTop: "2%",
          }}
          showMuteControls={true}
          videoStream={localStream}
          autoPlay
          muted
        ></Video>
        <br />
        {sidebar}
        <br />
        {activeTab === "chat" && (
          <ChatBox
            user={{
              username: username, // Directly pass the username here
              uid: (this.socket && this.socket.id) || "",
            }}
            messages={messages}
            sendMessage={(message) => {
              this.setState((prevState) => {
                return { messages: [...prevState.messages, message] };
              });
              this.state.sendChannels.map((sendChannel) => {
                sendChannel.readyState === "open" &&
                  sendChannel.send(JSON.stringify(message));
              });
              this.sendToPeer("new-message", JSON.stringify(message), {
                local: this.socket.id,
              });
            }}
            handleCloseChat={this.toggleChatBox}
          />
        )}
        {activeTab === "participants" && (
          <ParticipantsTab handleCloseChat={this.toggleTabs} />
        )}
        <EmojisFlying emojis={emojis} />
        <CallControls
          localStream={this.state.localStream}
          remoteStreams={this.state.remoteStreams} // Make sure remoteStreams is passed
          handleDisconnect={this.handleDisconnect}
          ScreenStream={this.getScreenShare}
          sendEmojiToRemote={this.sendEmojiToRemote}
          toggleChatBox={this.toggleChatBox}
          toggleTabs={this.toggleTabs}
          participantsCount={this.state.participantsCount}
          activeTab={activeTab}
          toggleActiveTab={this.toggleActiveTab}
        />
      </div>
    );
  }
}

export default Call;
