
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Call from './components/CallLogin';
import Login from './components/Login';
import AdminLandingPage from './components/AdminLandingPage';
import PackagePage from './components/PackagePage';
import CreatePackage from './components/CreatePackage';
import ViewPackage from './components/ViewPackage';
import AddFeature from './components/AddFeature';
import CustomerViewPackage from './components/CustomerViewPackage';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/:room" element={<Call username={window.location.search.substring(1).split("=")[1]} />} /> 
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/adminlanding" element={<AdminLandingPage/>} />
      <Route path="/addfeatures" element={<AddFeature/>} />
      <Route path="/PackagePage" element={<PackagePage/>} />
      <Route path="/CreatePackage" element={<CreatePackage/>} />
      <Route path="/ViewPackage" element={<ViewPackage/>} />
      <Route path="/CustomerViewPackage" element={<CustomerViewPackage/>} />
      {/* <Route path="/:room" element={<Call username={window.location.search.substring(1).split("=")[1]} />} /> */}
    </Routes>
   
  );
}
export default App;
